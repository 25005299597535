.NSF-AccountPage {
  > h1 {
    font-size: 1.5rem;
    font-weight: 300;
    margin-left: .5rem;
  }

  * {
    font-family: $font;
  }

  > .NSF-Row {
    > * {
      flex: 1;
      margin: 1rem .5rem;
      border-radius: 2px;
    }
  }
}