.NSF-DataCard {
  background: $color-white;
  border-radius: 2px;
  padding: 2rem;
  display: flex;
  flex-direction: row;

  width: 400px;

  > *:first-child {
    flex: 2;
  }
  > *:last-child {
    flex: 1;
  }

  &__info {
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;

    line-height:  1.5;
    font-size: 1rem;
    font-weight: 300;
  }

  &__visualization {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > h3 {
      font-weight: 300;
      font-size: 0.9rem;
      color: $color-unselected-text;
      margin-top: .5rem
    }

    > h2 {
      font-weight: 400;
      margin-top: .25rem;
      color: $color-general-text;
      font-size: 1rem;
    }
  }
}