.NSF-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  min-height: 100vh;
  min-width: 100vw;
  background: no-repeat center center;
  background-image: url('./assets/images/background.png');
}

.NSF-Layout {
  display: flex;
  flex-direction: row;
  height: 100%;
  box-shadow: $shadow-tall;
}

.NSF-App {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  width: 800px;
}

.NSF-Portal {
  flex: 1;
  background: $color-gray;
  padding: 1rem;
}
