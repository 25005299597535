.NSF-Sidebar {
  display: flex;
  flex-direction: column;
  background: $color-dark-black;
  color: $color-unselected-text;
  max-width: 25%;
}

.NSF-BrandBlock {
  height: $header-height;
  min-height: $header-height;
  padding: 2rem;
  display: flex;
  background: $color-dark-red;
  align-items: center;
  justify-content: center;

  > a > img {
    max-height: 30px;
  }
}

.NSF-Navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  > * {
    padding: 3rem;
  }

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    a,
    li {
      width: 100%;
      font-family: $font;
    }
  }
}

.NSF-NavigationLink {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  color: $color-unselected-text;
  text-decoration: none;
  margin: 0.5rem 0rem;
  transition: .2s all ease-in-out;
  border-left: 2px solid transparent;

  &:hover {
    color: $color-white;
  }

  > * {
    font-weight: 300;
    font-size: 1.2rem;
    margin: 0rem 0.5rem;
  }

  &--Active {
    transform: translateX(1rem);
    border-color: $color-dark-red;
    color: $color-white;

  }
}

div.NSF-NavigationLink {
  color: $color-general-text;

  &:hover {
    color: $color-general-text;
  }
}

.NSF-NaviCompany,
.NSF-NaviHelp {
  font-family: $font;
  p {
    font-size: 1.1rem;
    font-weight: 300;
    margin: 1rem 0rem;
  }
  b {
    font-weight: 500;
    color: $color-white;
  }
}

.NSF-NaviCompany__someList {
  svg {
    margin-right: 1rem;
    font-size: 1.5rem;
    color: $color-white;
  }
}
