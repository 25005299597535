.NSF-Input {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: .5rem 0rem ;

  > * {
    font-family: $font;
  }

  > input {
    background: $color-gray;
    border: none;
    padding: .5rem;
  }

  &__label {
    padding:1rem 0rem .5rem;
    color: $color-light-black;
    font-weight: 300;
  }

  &--Error {

  }
}
