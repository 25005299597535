$color-dark-red: #8e1b19;
$color-light-red: #ad3f37;
$color-dark-black: #282828;
$color-light-black: #383838;
$color-unselected-text: #b7b7b7;
$color-general-text: #585857;
$color-gray: #eff2f2;
$color-white: #ffffff;

$font: "Josefin Sans", sans-serif;

// Font-Size
$font-size-header-greeting: 5.583em;
$font-size-header-title: 1.75em;
$font-size-h2-accordion: 1.5625em;
$font-size-h3-nav: 1.3125em;

$font-weight-thin: 300;

$base-font-size: 0.8em;

// Line-Height
$base-line-height: 1.5;
$nav-line-height: 1.86;
$header-line-height: 1.25;

// Measurements
$header-height: 200px;

$shadow-tall:0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

*,
all {
  font-family: $font;
  box-sizing: border-box;
}

.NSF-Row {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.NSF-Column {
  display: flex;
  flex-direction: Column;
  width: 100%;
}