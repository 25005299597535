.NSF-ChangePassword {
  background: $color-white;
  box-sizing: border-box;
  padding: 2rem;

  > h2 {
    font-size: 1.5rem;
    font-weight: 300;
  }

  > p {
    font-weight: 300;
    padding: .5rem 0rem;
    line-height: 1.5;
  }

  &__divider {
    border-bottom: 1px solid $color-gray;
    margin: 3rem 1rem 2rem;
  }

  &__button {
    border: none;
    background: $color-dark-red;
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 300;
    color: $color-white;
    margin-top: 2rem;
    border-radius: 2px;
  }
}