.NSF-AccountInfo {
  background: $color-white;
  padding: 2rem;
  box-sizing: border-box;

  > h3 {
    font-weight: 300;
    font-size: 1.5rem;

    b {
      font-weight: 400;
    }
  }
}