.NSF-Login {
  display: flex;
  flex-direction: row;
  max-width: 700px;
  box-shadow: $shadow-tall;

  > * {
    flex: 2;
  }

  &__description {
    flex: 3;
    background: $color-dark-red;
    font-family: $font;
    padding: 3rem;
    color: $color-white;
    font-weight: 200;

    > img {
      width: 150px;
    }

    > h1 {
      font-size: 2rem;
      margin: 1rem 0rem;
    }

    > p {
      line-height: 1.5;
      font-size: 0.9rem;
      margin-bottom: 4rem;
    }
  }
}