$header-color: #fff;
$header-color-secondary: #bbb;
$font-weight-thin: 300;
$font-size-header-greeting: 15px;

.NSF-Header {
  flex: 1;
  display: flex;
  justify-content: center;
  background-image: url("../../assets/images/NSF_hero.jpg");
  background-size: cover;
  max-height: $header-height;
  min-height: $header-height;
}

.NSF-Header__textBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 30px;
  font-family: $font;
  font-weight: 300;
  color: $color-white;

  > h2 {
    margin: 0.5rem 0rem;
    font-size: 3rem;
  }

  > h3 {
    text-transform: uppercase;
    font-size: 1.2rem;
  }
}
