.NSF-LoginForm {
  background: $color-white;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  justify-content: center;
  align-items: center;

  &__checkBox {
    font-family: $font;
    width: 100%;
    padding: .5rem 0rem;
    font-weight: 200;

    > input {
      border-radius: 0px;
      border: 1px solid black;
    }
  }

  &__button {
    font-weight: 200;
    cursor: pointer;
    padding: .5rem;
    width: 100%;
    border: none;
    background: $color-dark-red;
    color: $color-white;
    text-transform: uppercase;
    font-size: .8rem;
  }
}