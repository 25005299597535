.NSF-PortalContent {
  display: flex;
  flex: 1;
  background: $color-gray;
  font-family: $font;
  font-weight: 300;
}

.NSF-PortalCard {
  width: 50%;
  background: $color-white;
  height: 200px;
  padding: 1rem;
  border-radius: 2px;

  > code {
    display: block;
    background: $color-dark-black;
    margin: 1rem 0rem;
    color: #fff;
    padding: 1rem;
    line-height: 1.5;
    border-radius: 4px;
  }
}
